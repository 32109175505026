const styles = (theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  homeLinkContainer: {
    justifyContent: 'flex-start',
    width: '100%'
  },
  homeLinkButton: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
      marginBottom: 5
    }
  },
  formRoot: {
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  mainContainer: {
    width: '50%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '10vh',
    [theme.breakpoints.down('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '85%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  mainHeadingContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    justify: 'center',
    marginTop: 5,
    marginBottom: 64
  },
  mainHeadingTypography: {
    fontFamily: 'Bogle',
    fontSize: 32,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  formContainer: {
    justifyContent: 'center'
  },
  item: {
    marginBottom: 16,
    marginTop: 16,
    paddingLeft: 16,
    paddingRight: 16
  },
  textField: {
    width: '100%'
  },
  passwordInstructionsHeadingText: {
    fontFamily: 'Bogle',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left',
    marginBottom: 8
  },
  tickIcon: {
    height: 12,
    width: 12,
    marginRight: 7
  },
  crossIconRoot: {
    fill: '#CB2022'
  },
  passwordInstructionsText: {
    fontFamily: 'Bogle',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left',
    display: 'inline'
  },
  footerContainer: {
    marginTop: 'auto',
    width: '100%'
  },
  progressBar: {
    height: 8,
    background:
      'linear-gradient(270deg, #0071CE 0%, rgba(0, 113, 206, 0.5) 100%)',
    //width: '75%',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px'
  },
  footerActionRow: {
    marginTop: 16,
    marginBottom: '2vw',
    justifyContent: 'space-between',
    marginLeft: '2vw',
    marginRight: '2vw',
    alignItem: 'baseline'
  },
  footerText: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left'
  },
  footerTextOverride: {
    alignItem: 'center'
  },
  continueButton: {
    borderRadius: '20px',
    background: '#0071CE'
  },
  continueButtonLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'none',
    color: '#FFFFFF'
  },
  eye: {
    cursor: 'pointer'
  },
  footerTextContainer: {
    display: 'flex',
    alignItems: 'center'
  }
});

export default styles;
