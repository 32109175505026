import axios from 'axios';
import _ from 'lodash';
import configuration from '../config/config';
import api from '../constants/api';
import API from '../constants/common';
import { getCookie } from '../utils/common';
import { getIamAuthToken } from '../utils/jwt';
import xss from 'xss';
import analytics from './analytics';
import { removeLoginTokens } from '../utilities/utils';
import { HEADER } from '../constants/keywords';
import { logger } from './logger';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(null);
    }
  });

  failedQueue = [];
};

const refreshTokenCall = (error) => {
  const originalRequest = error?.config;

  if (originalRequest.url === api.refreshToken) {
    removeLoginTokens();
    return Promise.reject(error);
  }
  if (
    error?.response?.status === 401 &&
    !originalRequest._retry &&
    !originalRequest.skipAuthRefresh
  ) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then(() => {
        return axiosInstance(originalRequest);
      });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return axiosInstance({
      url: api.refreshToken,
      method: 'POST',
      headers: { [HEADER.AUTH_TOKEN]: xss(localStorage.getItem('guesttoken')) },
      data: { refreshToken: localStorage.getItem('refreshToken') },
      skipAuthRefresh: true
    })
      .catch(() => {
        processQueue(error);
        isRefreshing = false;
        window.location.href = '/login';
        return Promise.reject(error);
      })
      .then((tokenRefreshResponse) => {
        // code-gate issue resolved
        const sanitizedtoken = xss(tokenRefreshResponse.data.authToken);
        localStorage.setItem('token', sanitizedtoken);
        processQueue(null);
        return axiosInstance(originalRequest);
      })
      .finally(() => {
        isRefreshing = false;
      });
  }

  return Promise.reject(error);
};

const axiosInstance = axios.create({});

export const axiosIns = axiosInstance;

const apiCall = (
  url,
  method = API.METHOD.GET,
  payload = {},
  skipAuthRefresh = false
) => {
  try {
    const options = {
      url,
      method,
      skipAuthRefresh
    };

    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      options.data = payload;
    }
    if (method === 'GET' && Object.keys(payload).length !== 0) {
      url += '?';
      const searchParams = new URLSearchParams([...Object.entries(payload)]);
      url += searchParams.toString();
      options.url = url;
    }

    return axiosInstance(options)
      .then((response) => response)
      .catch((error) => ({
        error: error?.response?.data,
        status: error?.response?.status
      }));
  } catch (error) {
    return error;
  }
};

const onRequest = (config) => {
  const serviceHeader = {
    'WM_CONSUMER.ID': configuration.consumerId,
    'WM_QOS.CORRELATION_ID': `${
      sessionStorage.getItem('cs_session') || 'cs_session_default'
    }_${Date.now()}`
  };
  if (configuration.env === 'dev') {
    serviceHeader[HEADER.SVC_NAME] = 'CUSTOMER-SPARK-SERVICE';
    serviceHeader[HEADER.SVC_ENV] = 'stg:1.0.0';
  }
  if (config.skipAuthRefresh) {
    serviceHeader[HEADER.AUTH_TOKEN] = getCookie('guesttoken');
  } else {
    serviceHeader.authorization = `Bearer ${localStorage.getItem('token')}`;
    serviceHeader[HEADER.AUTH_TOKEN] = getIamAuthToken(
      localStorage.getItem('token')
    );
    serviceHeader[HEADER.AUTH_TOKEN] = getCookie('guesttoken');
    serviceHeader[HEADER.USER_ID] = localStorage.getItem('panelistId');
  }

  config.headers = { ...API.DEFAULT_HEADER, ...serviceHeader };

  const { url, method, headers, timeout, maxContentLength, skipAuthRefresh } =
    config;

  const pulseData = {
    url,
    method,
    panelistId: headers['CS_CONSUMER.PANELIST_ID'] ?? '',
    consumerId: headers['WM_CONSUMER.ID'] ?? '',
    correlationId: headers['WM_QOS.CORRELATION_ID'] ?? '',
    timeout,
    maxContentLength,
    skipAuthRefresh
  };
  logger.info('Api request', pulseData);

  return Promise.resolve(config);
};

const onRequestError = (error) => {
  logger.error('Failed to send API request', { error });
  return Promise.reject(error);
};

const onResponse = (response) => {
  const { config, status, statusText } = response;

  const { url, method, headers, timeout, maxContentLength, skipAuthRefresh } =
    config;

  const pulseData = {
    url,
    method,
    timeout,
    panelistId: headers['CS_CONSUMER.PANELIST_ID'] ?? '',
    consumerId: headers['WM_CONSUMER.ID'] ?? '',
    correlationId: headers['WM_QOS.CORRELATION_ID'] ?? '',
    maxContentLength,
    skipAuthRefresh,
    status,
    statusText
  };
  logger.info('Api response', pulseData);

  return response;
};

const onResponseError = (error) => {
  let pulseData;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    const path = error.config.url.split('/');

    analytics.trackAPIEvents(
      'API_ERROR',
      error?.message,
      path?.[path.length - 1]
    );

    const { response, message, name } = error;
    const { data, config, status, statusText } = response;

    const apiResponseData = data;

    const { url, method, headers, timeout, maxContentLength, skipAuthRefresh } =
      config;

    //const
    pulseData = {
      errorName: name,
      errorMessage: message,
      url,
      method,
      status,
      statusText,
      apiResponseData,
      panelistId: headers['CS_CONSUMER.PANELIST_ID'] ?? '',
      consumerId: headers['WM_CONSUMER.ID'] ?? '',
      correlationId: headers['WM_QOS.CORRELATION_ID'] ?? '',
      timeout,
      maxContentLength,
      skipAuthRefresh
    };
    logger.error('Api request failed', pulseData);

    return refreshTokenCall(error);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    pulseData = {
      reqTimeout: true,
      errorDetails: error.toJSON()
    };
    logger.error('Api request failed', pulseData);
  } else {
    // Something happened in setting up the request that triggered an Error
    pulseData = {
      reqSetupError: true,
      errorDetails: error.toJSON()
    };
    logger.error('Api request failed', pulseData);
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, onRequestError);

axiosInstance.interceptors.response.use(onResponse, onResponseError);

export default apiCall;
