import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonLoader = ({ height, width, type = "text" }) => {
  return (
    <Skeleton
      type={type}
      style={{
        height: height,
        width: width ? width : parseInt(height) - 100,
      }}
    />
  );
};

SkeletonLoader.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.number,
  type: PropTypes.oneOf(["text", "rect"]),
};

export default SkeletonLoader;
