const styles = (theme) => ({
    zommableImage: {
      objectFit: "contain",
      transition: "transform 0.2s",
      touchAction: "none",
    },
    outerDivContainer: {
      overflow: "hidden",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "center",
    },
    skeletonOuterDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px 5px 10px 5px",
    },
    imgAlters: {
      filter: "drop-shadow(0px 0px 10px rgba(51, 51, 51, 0.1))",
      borderRadius: 4,
      boxShadow: "0px 0px 10px rgb(51 51 51 / 10%)",
    },
    transformComponent: {},
    zoomImageContainer: {
      "&:hover": {
        cursor: "crosshair !important",
      },
    },
  });
  
  export default styles;
  