import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { onAppDownloadNowClick } from '../../utils/common';
import { Close } from '@material-ui/icons';
import useConfigContext from '../../utils/useConfigContext';
import {
  AndroidAppLinkWithQrCode,
  IosAppLinkWithQrCode
} from '../MobileAppLinkWithQrCode/index.ts';

function MobileBanner({ classes }) {
  const [hideBanner, setHideBanner] = useState(false);

  const showMobileBanner = useConfigContext('showMobileBanner') === true;

  if (hideBanner || !showMobileBanner) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <AndroidAppLinkWithQrCode />

      <Box className={classes.appIconWrapper}>
        <img
          src="/assets/images/mobile/app-icon.png"
          alt="customer spark app icon"
          className={classes.appIcon}
        />
      </Box>

      <Box className={classes.mobileBannerImageWrapper}>
        <Box>
          <Typography className={classes.mobileBannerTitle}>
            <FormattedMessage id="LANDING_PAGE_MOBILE_BANNER_TITLE" />
          </Typography>
          <Typography className={classes.mobileBannerSubtitle}>
            <FormattedMessage id="LANDING_PAGE_MOBILE_BANNER_SUBTITLE" />
          </Typography>
        </Box>
        <div>
          <button
            className={classes.downloadTextBtn}
            onClick={onAppDownloadNowClick}
          >
            <FormattedMessage id="LANDING_PAGE_MOBILE_BANNER_DOWNLOAD_BTN" />
          </button>
        </div>
        <img
          src="/assets/images/mobile/mobile-marketing-banner.png"
          alt="customer spark app banner"
          className={classes.mobileBannerImage}
        />
      </Box>

      <IosAppLinkWithQrCode />
      <IconButton
        aria-label="close"
        data-testid="close-button"
        className={classes.closeContainer}
        onClick={() => setHideBanner(true)}
      >
        <Close className={classes.closeIcon} />
      </IconButton>
    </Box>
  );
}

export default withStyles(styles)(MobileBanner);
