import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SkeletonLoader from "../SkeletonLoader";
const ZoomImage = ({
  src,
  height,
  classes,
  flexBasis,
  addOnClasses = {},
  borderOnZoom = true,
  initialZoomedIn = false,
}) => {
  const [zoom, setZoom] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {
    zommableImage,
    outerDivContainer,
    imgAlters,
    skeletonOuterDiv,
    transformComponent,
    zoomImageContainer,
  } = classes;
  const { zoomAddonOuterDiv } = addOnClasses;
  const outerDivStyle = {
    flexBasis: flexBasis,
  };
  useEffect(() => {
    if (initialZoomedIn) {
      setZoom(true);
    }
  }, [initialZoomedIn]);

  useEffect(() => {
    const fetchImage = () => {
      axios
        .get(src, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = window.URL.createObjectURL(new Blob([response.data]));
          setImageSrc(blob);
          setIsLoading(false);
        })
        .catch((err) => {
          setImageSrc(src);
          setIsLoading(false);
        });
    };

    fetchImage();
  }, []);

  const handleMouseOver = (e, zoomIn) => {
    setZoom(true);
    zoomIn(0.5, 0);
  };

  const handleMouseOut = (e, resetTransform, centerView) => {
    setZoom(false);
    resetTransform(0);
    centerView(undefined, 0);
  };

  return (
    <>
      {isLoading ? (
        <div
          className={skeletonOuterDiv}
          style={{
            ...outerDivStyle,
            height: height,
          }}
        >
          <SkeletonLoader height={height} />
        </div>
      ) : (
        <div
          className={clsx(outerDivContainer, imgAlters, zoomAddonOuterDiv)}
          style={{
            ...outerDivStyle,
            border: borderOnZoom && zoom ? "2px solid #0370cd" : "",
            height: zoom ? parseInt(height) - 4 : height,
          }}
          data-testid="zoomable-image"
        >
          <TransformWrapper
            minScale={1}
            maxScale={5}
            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
            limitToBounds={true}
            centerZoomedOut={true}
          >
            {({ zoomIn, resetTransform, centerView }) => {
              return (
                <TransformComponent componentClass={transformComponent}>
                  <div
                    data-testid={"zoom-image-container"}
                    className={zoomImageContainer}
                    onMouseEnter={(e) => handleMouseOver(e, zoomIn)}
                    onMouseOut={(e) =>
                      handleMouseOut(e, resetTransform, centerView)
                    }
                  >
                    <img
                      className={zommableImage}
                      src={imageSrc}
                      height={height}
                      width={"100%"}
                      alt="uploaded"
                      key={imageSrc}
                    />
                  </div>
                </TransformComponent>
              );
            }}
          </TransformWrapper>
        </div>
      )}
    </>
  );
};

ZoomImage.propTypes = {
  //image height
  height: PropTypes.string.isRequired,
  //string x%,
  flexBasis: PropTypes.string,
  addOnClasses: PropTypes.object,
  //to show surrounding border upon zoom
  borderOnZoom: PropTypes.bool,
  //to make image zoomed when initially loading
  initialZoomedIn: PropTypes.bool,
};

export default withStyles(styles)(ZoomImage);
