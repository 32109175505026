import React, { FC, useEffect } from 'react';
import { DataTypes, SurveySelectors } from '@walmart/survey-taker-api-js-sdk';
import { useSelector } from 'react-redux';

type Props = {
  onAnswerChange: (answer: any) => void;
  allAnswers: Record<string, any>;
};

const Text: FC<Props> = ({ onAnswerChange, allAnswers }) => {
  const currentQuestion = useSelector(
    SurveySelectors.selectCurrentQuestion
  ) as DataTypes.TextQuestion;

  useEffect(() => {
    onAnswerChange({ [currentQuestion.questionId]: 1 });
  }, [onAnswerChange, currentQuestion]);

  return <></>;
};

export default Text;
