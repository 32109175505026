import React from 'react';
import {
  addImageAttr,
  additionalImageAttribute,
  getImageHeight
} from '../../utilities/utils';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import clsx from 'clsx';
import ZoomImage from '../ZoomImage';
import ImageCarousalWithZoom from '../ImageCarousalWithZoom';

const ContentType = (props) => {
  const { addOnClasses, classes, isMobile, uploadedImages } = props;

  const {
    outerZoomConatiner,
    zoomAddonOuterDiv,
    mobileViewFullWidth,
    sliderContainer
  } = classes;


  const getFlexBasis = (noOfImages) => {
    let flexBasis;
    if (noOfImages === 1) {
      flexBasis = '98%';
    } else if (noOfImages % 2 === 0) {
      flexBasis = '46%';
    } else {
      flexBasis = '31%';
    }
    return flexBasis;
  };

  const heightImages = getImageHeight(
    window.innerWidth,
    uploadedImages?.length
  );

  const getContent = () => {
    //if image type media is uploaded
    return  (
      isMobile ? (
        <div className={sliderContainer}>
          <ImageCarousalWithZoom
            uploadedImages={uploadedImages}
            sliderHeight={!isMobile ? 400 : 200}
            sliderWidth={!isMobile ? 450 : 290}
            dialogImageHeight={450}
            isMobile={isMobile}
          />
        </div>
      ) : (
        <div className={outerZoomConatiner}>
          {uploadedImages?.map((image) => (
            <ZoomImage
              src={addImageAttr({
                mediaUrl: image,
                ...additionalImageAttribute.large_bg_white_1440
              })}
              key={image}
              height={heightImages}
              flexBasis={getFlexBasis(uploadedImages?.length)}
              addOnClasses={{ zoomAddonOuterDiv }}
            />
          ))}
        </div>
      )
    );
  };

  return (
    <div
      className={clsx(isMobile ? mobileViewFullWidth : addOnClasses?.container)}
    >
      {uploadedImages?.length > 0 && getContent()}
    </div>
  );
};

ContentType.propTypes = {};

export default withStyles(styles)(ContentType);
