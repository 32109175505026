import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  withStyles
} from '@material-ui/core';
import { Modal } from '@walmart-web/livingdesign-components';
import styles from './styles';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import SkeletonLoader from '../SkeletonLoader';
import { addImageAttr, additionalImageAttribute } from '../../utilities/utils';
const ImageCarousalWithZoom = ({
  uploadedImages,
  classes,
  sliderHeight,
  sliderWidth,
  dialogImageHeight,
  isMobile
}) => {
  const {
    padding10,
    dialogImageThumbnail,
    flexCenter,
    dialogContainerMobile,
    dialogContainerWeb,
    imageFitContain,
    imageHoverCrosshair
  } = classes;
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    dots: true,
    lazyLoad: 'progressive',
    swipeToSlide: true
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [imageZoomedOn, setImageZoomedOn] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState([]);

  const handleClose = (e) => {
    setOpenDialog(false);
  };
  const handleMouseClick = (media) => {
    setImageZoomedOn(media);
    setOpenDialog(true);
  };
  const images = uploadedImages?.map((url) => ({ url }));
  const handleThumbNailClick = (media) => {
    setImageZoomedOn(media);
  };
  useEffect(() => {
    const fetchImage = () => {
      //loading only first image, rest carousel will take care of loading
      //loading first so that user don't see that image is loading slowly slowly
      axios
        .get(
          addImageAttr({
            mediaUrl: images?.[0]?.url,
            ...additionalImageAttribute.large_bg_white_1440
          }),
          {
            responseType: 'blob'
          }
        )
        .then((response) => {
          const blob = window.URL.createObjectURL(new Blob([response.data]));
          setImageSrc((curr) => [
            { ...images?.[0], blob },
            ...images?.slice(1)
          ]);
          setIsLoading(false);
        })
        .catch(() => {
          setImageSrc((curr) => [{...images}]);
          setIsLoading(false);
        });
    };

    fetchImage();
  }, []);
  return isLoading ? (
    <SkeletonLoader height={sliderHeight} width={sliderWidth} />
  ) : (
    <div style={{ width: sliderWidth, height: sliderHeight }}>
      <Slider {...sliderSettings}>
        {imageSrc?.map((media, index) => (
          <img
            src={
              media?.blob ||
              addImageAttr({
                mediaUrl: media?.url,
                ...additionalImageAttribute.large_bg_white_1440
              })
            }
            key={media?.url}
            width={sliderWidth}
            alt='uploaded'
            height={sliderHeight}
            className={imageFitContain}
            onClick={() => handleMouseClick(media)}
            data-testid={`slider-image-${index}`}
          />
        ))}
      </Slider>
      <Modal
        onClose={handleClose}
        isOpen={openDialog}
        title="image"
        size="small"
      >
        <div
          className={clsx(
            flexCenter,
            isMobile ? dialogContainerMobile : dialogContainerWeb
          )}
        >
          {imageZoomedOn && (
            <TransformWrapper
              maxScale={3}
              alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
              limitToBounds={true}
              centerZoomedOut={true}
              key={imageZoomedOn}
            >
              <TransformComponent>
                <div className={clsx(imageHoverCrosshair)}>
                  <img
                    src={addImageAttr({
                      mediaUrl: `${imageZoomedOn?.url}`,
                      ...additionalImageAttribute.large_bg_white_1440
                    })}
                    width={'100%'}
                    className={clsx(imageFitContain)}
                    height={dialogImageHeight - 20}
                  />
                </div>
              </TransformComponent>
            </TransformWrapper>
          )}
        </div>
        {/* list of photos at the bottom */}
        <div className={flexCenter}>
          <div className={clsx(flexCenter, padding10)}>
            {images?.map((media) => (
              <img
                src={`
                ${addImageAttr({
                  mediaUrl: `${media?.url}`,
                  ...additionalImageAttribute?.thumbnail_bg_white_50
                })}`}
                height={50}
                width={50}
                className={dialogImageThumbnail}
                key={media?.url}
                style={{
                  border: imageZoomedOn?.url === media?.url ? '2px solid #0370cd' : ''
                }}
                role={'button'}
                alt={`thumbnail uploaded media ${media?.url}`}
                onClick={() => handleThumbNailClick(media)}
                data-testid={`thumbnail-uploaded-media-${media?.url}`}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

ImageCarousalWithZoom.propTypes = {
  data: PropTypes.shape({
    //content resource url provied by feedback service
    url: PropTypes.string.isRequired,
    //type of media
    type: PropTypes.string.isRequired
  }),
  classes: PropTypes.object.isRequired,
  sliderHeight: PropTypes.number.isRequired,
  sliderWidth: PropTypes.number,
  isLoading: PropTypes.bool,
  dialogImageHeight: PropTypes.number.isRequired,
  dialogImageWidth: PropTypes.number.isRequired,
  //is this component renedering for video type questioin
  isVideoQuestion: PropTypes.bool,
  // is it rendering for mobile screen
  isMobile: PropTypes.bool
};

export default withStyles(styles)(ImageCarousalWithZoom);
