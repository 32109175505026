const config = {
  dev: {
    env: 'dev',
    api: {
      customerSpark: {
        domain:
          'https://customer-spark-service-dev.spark-panel.k8s.glb.us.walmart.net',
        path: '/customer-spark'
      }
    },
    consumerId: 'dbff1e56-cde4-44aa-aa96-69369bdc2adc',
    consumerId_mobile: '4588f7f6-3441-4f12-93e5-5b7fe9e8c129',
    allowed_consumers: {
      survey_taker_ui: '6c9ecb1e-9180-11ed-a1eb-0242ac120002'
    },
    svc_version: '1.0.0',
    iam: {
      url: 'https://qa.iam.platform.prod.walmart.com/platform-iam-server/iam/authnService/guest',
      realm: 'SPARK_PANEL_CUSTOMER_PORTAL',
      realm_mobile: 'SPARK_PANEL_CUSTOMER_PORTAL_MOBILE',
      serviceName: 'platform-iam-server',
      serviceEnv: 'qa'
    },
    mobile: {
      appIDs: ['F7HDEWM8SU.com.walmart.customerspark'],
      androidPackageName: 'com.walmart.spark.beta',
      SHA256Fingerprint: [
        'A6:82:63:B3:10:FA:8F:97:D1:9B:76:E5:11:2C:30:13:02:04:79:06:9D:F5:48:85:D0:4E:19:B3:D8:B8:72:D2'
      ]
    }
  },
  stage: {
    env: 'stage',
    api: {
      customerSpark: {
        domain:
          'https://developer.api.us.stg.walmart.com/api-proxy/service/customer-spark/service/v2',
        path: '/customer-spark'
      }
    },
    consumerId: '792bee75-3174-4301-af51-f8d1c7e0886f',
    consumerId_mobile: '511e1055-7c74-4597-b291-fffcefe77fe0',
    allowed_consumers: {
      survey_taker_ui: 'e2fab314-c36f-4a8e-8024-7b6406955c03'
    },
    svc_version: '2.0.0',
    iam: {
      url: 'https://stg.iam.platform.prod.walmart.com/platform-iam-server/iam/authnService/guest',
      realm: 'SPARK_PANEL_CUSTOMER_PORTAL',
      realm_mobile: 'SPARK_PANEL_CUSTOMER_PORTAL_MOBILE',
      serviceName: 'platform-iam-server',
      serviceEnv: 'stg'
    },
    mobile: {
      appIDs: ['F7HDEWM8SU.com.walmart.customerspark'],
      androidPackageName: 'com.walmart.spark.beta',
      SHA256Fingerprint: [
        'A6:82:63:B3:10:FA:8F:97:D1:9B:76:E5:11:2C:30:13:02:04:79:06:9D:F5:48:85:D0:4E:19:B3:D8:B8:72:D2'
      ]
    }
  },
  prod: {
    env: 'prod',
    api: {
      customerSpark: {
        domain:
          'https://developer.api.us.walmart.com/api-proxy/service/customer-spark/service/v1',
        path: '/customer-spark'
      }
    },
    consumerId: 'fc896b8b-78d4-492b-8f9a-00598c728a82',
    consumerId_mobile: '33c32eb1-f098-460a-96c6-6c7c349ef8a8',
    allowed_consumers: {
      survey_taker_ui: '391d09c9-8c9b-4dde-8412-a3647119c999'
    },
    svc_version: '1.0.0',
    iam: {
      url: 'https://prod1.iam.platform.prod.walmart.com/platform-iam-server/iam/authnService/guest',
      realm: 'SPARK_PANEL_CUSTOMER_PORTAL',
      realm_mobile: 'SPARK_PANEL_CUSTOMER_PORTAL_MOBILE',
      serviceName: 'platform-iam-server',
      serviceEnv: 'prod'
    },
    mobile: {
      appIDs: ['74G6H8XY8B.com.walmart.spark.mobile'],
      androidPackageName: 'com.walmart.customerspark',
      SHA256Fingerprint: [
        'BA:B7:D2:D4:6C:2E:A6:18:C9:64:D2:BD:2C:F6:94:4C:BF:23:61:FA:95:39:77:82:EE:22:93:6A:5F:3D:5C:48'
      ]
    }
  }
};

export default config;
