import {
  ALREADY_TAKEN,
  SAVE_ERROR,
  SURVEY_CLOSED,
  SURVEY_NOT_AUTHORIZED,
  SURVEY_NOT_EXIST,
  THANK_YOU
} from '../constants/keywords';
import { axiosIns } from '../utils/api';
import { logger } from '../utils/logger';

export const ASSETS_PATH = '/assets';

export const IMAGE_PATH = `${ASSETS_PATH}/images`;

export const objectToString = (currentObject) => {
  const TY = JSON.stringify(THANK_YOU);
  const SE = JSON.stringify(SAVE_ERROR);
  const AT = JSON.stringify(ALREADY_TAKEN);
  const SNE = JSON.stringify(SURVEY_NOT_EXIST);
  const SNA = JSON.stringify(SURVEY_NOT_AUTHORIZED);
  const SC = JSON.stringify(SURVEY_CLOSED);
  const CO = JSON.stringify(currentObject);

  switch (CO) {
    case TY:
      return 'THANK_YOU';
    case SE:
      return 'SAVE_ERROR';
    case AT:
      return 'ALREADY_TAKEN';
    case SNE:
      return 'SURVEY_NOT_EXIST';
    case SNA:
      return 'SURVEY_NOT_AUTHORIZED';
    case SC:
      return 'SURVEY_CLOSED';
    default:
      return '';
  }
};

export const removeLoginTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('panelistId');
  localStorage.removeItem('emailId');
  logger.setUserInfo({
    userId: 'unset'
  });
};

// For setting the session
export const setSession = () => {
  const sessionId = Date.now();
  logger.setSessionInfo(`${sessionId}`);
  sessionStorage.setItem('cs_session', sessionId);
};

export const truncate = (lineCount) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: lineCount || 1,
  WebkitBoxOrient: 'vertical'
});

export const handleFileUpload = (url, files) => {
  const formData = new FormData();
  files?.forEach((file) => {
    formData.append('files', file);
  });
  return axiosIns.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getSizeFromBytesToKBMB = (sizeInBytes) => {
  let sizeInMB = sizeInBytes / 1000 / 1000;

  if (sizeInMB < 1) {
    return `${sizeInMB * 1000} KB`;
  } else {
    return `${sizeInMB?.toFixed(2)} MB`;
  }
};

/**
 * predefined image size, corresponding setting will be required in torbit too
 * https://confluence.walmart.com/pages/viewpage.action?spaceKey=PGPT&title=Image+Resize-On-The-Fly
 */
export const additionalImageAttribute = {
  thumbnail_bg_white_50: {
    height: 50,
    width: 50,
    backgroundColor: "ffffff",
  },
  medium_bg_white_640: {
    height: 480,
    width: 640,
    backgroundColor: "ffffff",
  },
  large_bg_white_1280: {
    height: 720,
    width: 1280,
    backgroundColor: "ffffff",
  },
  large_bg_white_1080: {
    height: 720,
    width: 1080,
    backgroundColor: "ffffff",
  },
  large_bg_white_1260: {
    height: 900,
    width: 1260,
    backgroundColor: "ffffff",
  },
  large_bg_white_1440: {
    height: 1080,
    width: 1440,
    backgroundColor: "ffffff",
  },
  large_bg_white_1800: {
    height: 1440,
    width: 1800,
    backgroundColor: "ffffff",
  },
};

/**
 *if any of the odnWidth,odnHeight attribute is not configured in torbit then it will load original image
 * @param {Object} {
 * mediaUrl : url of the image,
 * height: resized height, same height config should be in torbit too
 * width: resized width, same width config should be in torbit too
 * backgroundColor: color to fill the empty space after resizing
 * }
 * @returns String , url with added additional query param odnWidth,odnHeight and odnBg
 */
 export const addImageAttr = ({ mediaUrl, height, width, backgroundColor }) => {
  const url = new URL(mediaUrl);
  url.searchParams.append("odnWidth", width);
  url.searchParams.append("odnHeight", height);
  url.searchParams.append("odnBg", backgroundColor);
  return url.toString();
};

//following utility fn will be used by Imagedisplay component to set fixed height of image
/**
 * @param {integer} viewPortWidth
 *  @param {integer} noOfImages
 * @return {string} height
 */
export const getImageHeight = (viewPortWidth, noOfImages) => {
  let height = "218px";
  //  x> sm && x<md
  if (viewPortWidth > 600 && viewPortWidth < 960) {
    if (noOfImages === 1) {
      height = "480px";
    } else {
      height = "280px";
    }
  } else if (viewPortWidth >= 960) {
    //x >md && x<lg
    if (noOfImages === 1) {
      height = "534px";
    } else {
      height = "300px";
    }
  }
  return height;
};