const styles = (theme) => ({
  questionTitle: {
    marginBottom: 0,
    [theme?.breakpoints?.up('sm')]: {
      marginBottom: 32
    }
  },
  zoomTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  zoomIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    width: 32,
    marginLeft: 8,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '50%'
  },
  zoomIcon: {
    fontSize: 20
  },
  subTitle: {
    color: theme.palette.text.main
  },
  mediaContainer: {
    padding: "0px 0 24px 0",
    placeItems: "start",
    width: "566px",
    minHeight: "319px",
    [theme.breakpoints.down("md")]: {
      width: "476px",
      minHeight: "318px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "534px",
      minHeight: "320px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "328px",
      minHeight: "200px",
      placeItems: "center",
    },
  },
  imageMediaContainer: {
    padding: "0px 0 32px 0",
    placeItems: "start",
    width: "100%",
    minHeight: "250px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "480px",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "534px",
      width: "80%",
    },
  },
  multiImageMediaContainer23: {
    padding: "0px 0 24px 0",
    placeItems: "start",
    width: "100%",
    minHeight: "330px",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  multiImageMediaContainer4: {
    padding: "0px 0 24px 0",
    placeItems: "start",
    width: "100%",
    minHeight: "250px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "540px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "630px",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "644px",
    },
  },
});
export default styles;
