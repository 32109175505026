import { Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import React from 'react';
import AnswerChoiceGrid from './AnswerChoiceGrid';
import { useSelector } from 'react-redux';
import { SurveySelectors } from '@walmart/survey-taker-api-js-sdk';
import parse from 'html-react-parser';
import { modifyRequiredQuestionHTMLString } from '../../../utils/common';
import { Body } from '@walmart-web/livingdesign-components';
import MediaWrapper from './MediaWrapper';

const useStyles = makeStyles(({ breakpoints }) => ({
  quesContainer: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: 16,
    margin: 16,
    maxWidth: '726px',
    [breakpoints.up(380)]: {
      width: 360
    },
    [breakpoints.up('sm')]: {
      rowGap: 24,
      width: 576,
      margin: 24
    },
    [breakpoints.up('md')]: {
      width: 726
    }
  },
  answerContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    margin: 16,
    [breakpoints.up('sm')]: {
      margin: 24
    }
  },
  requiredStar: {
    color: '#cb2022'
  }
}));

const QuestionContainer = (props) => {
  const classes = useStyles();

  const currentQuestionNumber = useSelector(
    SurveySelectors.selectCurrentQuestionNumber
  );
  const totalQuestions = useSelector(SurveySelectors.selectTotalQuestions);
  const currentQuestion = useSelector(SurveySelectors.selectCurrentQuestion);
  const currentQuestionHasContent = currentQuestion.uploadedMedia?.length > 0;
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
    >
      <Grid className={classes.quesContainer}>
        {!currentQuestion?.hideQuestionNumber && (
          <Body
            size="small"
            weight="400"
            as="p"
            color="gray160"
          >{`Question ${currentQuestionNumber} of ${totalQuestions}`}</Body>
        )}
        <Body size="large" weight="400" color="gray160">
          {parse(
            modifyRequiredQuestionHTMLString(
              '',
              currentQuestion.title,
              currentQuestion.required,
              classes.requiredStar,
              ''
            )
          )}
        </Body>
        {currentQuestionHasContent && (
          <MediaWrapper
            key={`${currentQuestion?.questionId}+${isMobile}`}
            isMobile={isMobile}
            showZoomMessage={isMobile ? false : true}
          />
        )}
        <Body size="medium" weight="400" as="p" color="gray160">
          {currentQuestion.description}
        </Body>
      </Grid>
      <Grid className={classes.answerContainer}>
        <AnswerChoiceGrid />
      </Grid>
    </Box>
  );
};

QuestionContainer.propTypes = {};

export default QuestionContainer;
