import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import Logo from '../../../components/Logo';
import { freeTexts } from '../constants';
import styles from '../styles';
import { useHistory } from 'react-router-dom';

const NewRegistrationLayout = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    footerButtonLabel = 'Continue',
    footerProgressPercentage = 75,
    footerBtnclickAction,
    disableFooterButton,
    registrationCompleted = false
  } = props;

  const history = useHistory();

  const handleHomeClick = () => {
    history.push('/home', 'homeLogoEvent');
  };

  const handleFooterBtnClick = () => {
    footerBtnclickAction();
  };

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.homeLinkContainer}>
        <Grid item className={classes.homeLinkButton}>
          <Logo callBack={handleHomeClick} />
        </Grid>
      </Grid>
      {props.children}
      <Grid container className={classes.footerContainer}>
        <Grid container className={classes.progressBarContainer}>
          <Grid item xs={12}>
            <div
              className={classes.progressBar}
              style={{ width: `${footerProgressPercentage}%` }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.footerActionRow}>
          <Grid item className={classes.footerTextContainer}>
            <Typography
              className={classes.footerText}
              classes={{ root: classes.footerTextOverride }}
              data-testid="registration-status-text"
            >
              {registrationCompleted
                ? freeTexts.footerText.registrationCompleted
                : freeTexts.footerText.completeRegsitration}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              classes={{
                root: classes.continueButton,
                label: classes.continueButtonLabel
              }}
              onClick={handleFooterBtnClick}
              disabled={disableFooterButton}
              data-testid="footer-btn"
            >
              {footerButtonLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewRegistrationLayout;
