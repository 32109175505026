const styles = (theme) => ({
  mobileBannerTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('768')]: {
      textAlign: 'center',
      fontSize: 30
    },
    [theme.breakpoints.up('1024')]: {
      fontSize: 32
    },
    [theme.breakpoints.up('1440')]: {
      fontSize: 42
    }
  },
  mobileBannerSubtitle: {
    display: 'none',
    fontWeight: 400,
    color: theme.palette.text.primary,
    fontStyle: 'italic',
    [theme.breakpoints.up('768')]: {
      display: 'block',
      textAlign: 'center',
      fontSize: 18
    },
    [theme.breakpoints.up('1024')]: {
      display: 'block',
      fontSize: 24
    }
  },
  downloadTextBtn: {
    background: 'none',
    border: 'none',
    padding: 0,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    [theme.breakpoints.up('768')]: {
      display: 'none'
    }
  },
  mobileBannerImage: {
    display: 'none',
    alignSelf: 'center',
    [theme.breakpoints.up('768')]: {
      width: '360px',
      display: 'block'
    },
    [theme.breakpoints.up('1024')]: {
      width: '470px'
    },
    [theme.breakpoints.up('1440')]: {
      width: '640px'
    }
  },
  mobileBannerImageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '24px',
    [theme.breakpoints.down('768')]: {
      marginTop: '0px'
    }
  },
  appIcon: {
    height: '50px',
    width: '50px'
  },
  appIconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('768')]: {
      display: 'none'
    }
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    borderBottom: `1px solid #DADCE0`,
    [theme.breakpoints.down('768')]: {
      padding: '16px',
      columnGap: '8px',
      borderBottom: 'none',
      boxShadow: '0px 0px 10px 0px #0000001A'
    },
    [theme.breakpoints.down('420')]: {
      justifyContent: 'flex-start'
    }
  },
  closeContainer: {
    position: 'absolute',
    right: 0
  },
  closeIcon: {
    height: 24,
    width: 24,
    [theme.breakpoints.up('1440')]: {
      height: 32,
      width: 32
    }
  }
});

export default styles;
