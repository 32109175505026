import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import QuestionContainer from './QuestionContainer';
import {
  SurveyActions,
  SurveySelectors,
  ReduxTypes
} from '@walmart/survey-taker-api-js-sdk';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import LuminateLoader from '../../components/LuminateLoader';
import SurveyMessage from './SurveyMessage';
import { Button } from '@walmart-web/livingdesign-components';
import { logger } from '../../utils/logger';
import { useScreenVisibilityTime } from '../../hooks/useScreenVisibilityTime';

const useStyles = makeStyles(() => ({
  outerContainer: {
    width: '100%',
    display: 'flex',
    height: '99.9vh',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  mainContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflowY: 'scroll'
  }
}));

const MicroSurveys = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state: locationState } = useLocation();
  const searchParams = new URLSearchParams(search);
  const panelistHash = searchParams.get('panelist_hash');
  const { surveyId } = useParams();

  const isFeedbackSurvey = locationState === 'feedbackSurvey';

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const loadingState = useSelector(SurveySelectors.selectLoading);
  const points = useSelector(SurveySelectors.selectPoints);
  const currentQuestionNumber = useSelector(
    SurveySelectors.selectCurrentQuestionNumber
  );
  const totalQuestions = useSelector(SurveySelectors.selectTotalQuestions);

  const startTime = useRef(new Date().getTime() / 1000);
  const { getScreenTimeSpent, resetScreenTime } = useScreenVisibilityTime();

  useEffect(() => {
    logger.info('SurveySnack: Start loading survey', {
      surveyId,
      panelist: panelistHash
    });
    dispatch(SurveyActions.loadSurvey({ surveyId, panelistHash }))
      .unwrap()
      .catch((error) => {
        logger.error('SurveySnack: Error in loading survey', {
          error: error,
          surveyId,
          panelist: panelistHash
        });
      });
  }, [dispatch, surveyId, panelistHash]);

  const onNextPress = () => {
    const endTime = new Date().getTime() / 1000;
    const spentTime = Math.round(endTime - startTime.current);
    const perQuestionSpentTime = getScreenTimeSpent();
    const isPartialSave = currentQuestionNumber < totalQuestions;
    logger.info('SurveySnack: Next button pressed', {
      currentQuestionNumber,
      totalQuestions,
      spentTime,
      isPartialSave,
      surveyId: surveyId
    });
    logger.clickStream('next_button_click', {
      currentQuestionNumber,
      totalQuestions,
      surveyId,
      isPartialSave,
      panelist: panelistHash
    });
    logger.analytics('question_analytics', {
      currentQuestionNumber,
      totalQuestions,
      surveyId,
      isPartialSave,
      perQuestionSpentTime,
      panelist: panelistHash
    });
    dispatch(SurveyActions.submitSurvey({ spentTime, isPartialSave }))
      .unwrap()
      .then(() => {
        resetScreenTime();
        if (isPartialSave) {
          dispatch(SurveyActions.goToNextQuestion());
        } else {
          setSubmitSuccess(true);
        }
      })
      .catch((error) => {
        const errorName = error.name ?? 'Error Saving Survey';
        const errorMessage = error.message ?? `survey: ${surveyId}`;
        logger.error('SurveySnack: Failed to submit survey', {
          errorName,
          errorMessage,
          isPartialSave,
          surveyId,
          currentQuestionNumber,
          totalQuestions,
          spentTime
        });
      });
  };

  if (submitSuccess) {
    return (
      <Grid container className={classes.outerContainer}>
        <Header />
        <Box>
          <SurveyMessage
            title={
              isFeedbackSurvey
                ? 'Thank you for your feedback!'
                : 'Congratulations!'
            }
            description={
              isFeedbackSurvey
                ? 'Your response will play a crucial role in shaping and improving the Weekly Quests for our entire community.'
                : `You completed a Quest! You just earned ${
                    points ?? ''
                  } points!`
            }
            actions={
              <Button
                variant="primary"
                size="medium"
                onClick={() => {
                  const isLoggedIn = !!localStorage.getItem('token');
                  if (isLoggedIn) {
                    history.replace(
                      isFeedbackSurvey
                        ? '/activities'
                        : '/activities/weekly-quests',
                      'previousScreenWasWeeklyQuest'
                    );
                  } else {
                    history.replace('/');
                  }
                }}
              >
                {isFeedbackSurvey ? 'Exit' : 'Ok'}
              </Button>
            }
            showConfetti={!isFeedbackSurvey}
          />
        </Box>
      </Grid>
    );
  }

  if (loadingState === ReduxTypes.RequestStateEnum.Pending) {
    return (
      <Grid container className={classes.outerContainer}>
        <LuminateLoader />
        <Header />
      </Grid>
    );
  }

  if (loadingState === ReduxTypes.RequestStateEnum.Failed) {
    return (
      <Grid container className={classes.outerContainer}>
        <Header />
        <Box>
          <SurveyMessage />
        </Box>
      </Grid>
    );
  }

  if (loadingState === ReduxTypes.RequestStateEnum.Succeeded) {
    return (
      <Grid container className={classes.outerContainer}>
        <Header />
        <Grid container className={classes.mainContainer}>
          <QuestionContainer />
        </Grid>
        <Footer
          onBackClick={() => {
            resetScreenTime();
            logger.info('SurveyContainer: back button pressed', {
              currentQuestionNumber,
              totalQuestions,
              surveyId
            });
            logger.clickStream('prev_button_click', {
              currentQuestionNumber,
              totalQuestions,
              surveyId,
              panelist: panelistHash
            });
            dispatch(SurveyActions.goToPreviousQuestion());
          }}
          onNextClick={onNextPress}
        />
      </Grid>
    );
  }

  return null;
};

export default MicroSurveys;
