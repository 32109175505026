import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';
import { IMAGE_TYPE } from '../../../constants/keywords';
import { withStyles } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ContentType from '../../../components/contentType';
import { useSelector } from 'react-redux';
import { SurveySelectors } from '@walmart/survey-taker-api-js-sdk';
import { Caption } from '@walmart-web/livingdesign-components';
const MediaWrapper = (props) => {
  const { classes, isMobile, showZoomMessage } = props;
  const currentQuestion = useSelector(SurveySelectors.selectCurrentQuestion);
  const uploadedMediaByBlobIds = useSelector(
    SurveySelectors.selectUploadedMediaUrlByBlobId
  );
  const uploadedImages = currentQuestion.uploadedMedia?.filter((media) => media?.type?.includes("image"))
  .map((media) => uploadedMediaByBlobIds[media.url]);

  const hasImageType = currentQuestion.uploadedMedia[0]?.type?.includes(IMAGE_TYPE);

  const getAddonClassesForContent = () => {
    
    if (!hasImageType) {
      return { container: classes.mediaContainer };
    }
  
    const mediaCount = currentQuestion.uploadedMedia?.length || 0;
  
    if (mediaCount > 3) {
      return { container: classes.multiImageMediaContainer4 };
    }
  
    if (mediaCount > 1) {
      return { container: classes.multiImageMediaContainer23 };
    }
  
    return { container: classes.imageMediaContainer };
  };

  return (
    <>
      {hasImageType && showZoomMessage ? (
        <div className={clsx(classes.questionTitle, classes.zoomTextContainer)}>
          <Caption as="span" data-testid="panel-text">
            {(isMobile ? 'select image' : 'Hover over image') + ' to zoom in'}
          </Caption>
          <div className={classes.zoomIconContainer}>
            <ZoomInIcon className={classes.zoomIcon} />
          </div>
        </div>
      ) : null}
      <ContentType
        key={`${currentQuestion?.uploadedMedia}+${isMobile}`}
        addOnClasses={getAddonClassesForContent()}
        uploadedImages={uploadedImages}
        isMobile={isMobile}
      />
    </>
  );
};
MediaWrapper.defaultProps = {
  showZoomMessage: true
};

MediaWrapper.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  index: PropTypes.number,
  isMobile: PropTypes.bool
};

export default withStyles(styles)(MediaWrapper);
