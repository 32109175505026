import { useCallback, useEffect, useRef } from 'react';

export const useScreenVisibilityTime = () => {
  const perScreenStartTime = useRef(new Date().getTime() / 1000);
  const perScreenTimeTaken = useRef(0);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Calculate time spent until tab change
        const currentTime = new Date().getTime() / 1000;
        const pageFocusDuration = currentTime - perScreenStartTime.current;
        perScreenTimeTaken.current += pageFocusDuration;
      } else {
        // reset start time when page back in focus
        perScreenStartTime.current = new Date().getTime() / 1000;
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // gets total time spent on page excluding the duration elapsed when page was not in focus
  const getScreenTimeSpent = useCallback(() => {
    const currentTime = new Date().getTime() / 1000;
    const pageFocusDuration = currentTime - perScreenStartTime.current;
    const totalTimeSpent = Math.round(pageFocusDuration + perScreenTimeTaken.current);
    return totalTimeSpent;
  }, []);

  const resetScreenTime = useCallback(() => {
    perScreenStartTime.current = new Date().getTime() / 1000;
    perScreenTimeTaken.current = 0;
  }, []);

  return {
    perScreenStartTime,
    perScreenTimeTaken,
    getScreenTimeSpent,
    resetScreenTime
  };
};
