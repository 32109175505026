const styles = (theme) => ({
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    padding10: {
      padding: "10px",
    },
    dialogImageThumbnail: {
      margin: "0px 4px",
      borderRadius: "4px",
    },
    dialogPaper: {
      margin: 0,
      width: "100%",
    },
    dialogContainerMobile: {
      minWidth: 300,
      minHeight: 450,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogContainerWeb: {
      minWidth: 600,
      minHeight: 450,
    },
    imageFitContain: {
      objectFit: "contain",
    },
    imageHoverCrosshair: {
      "&:hover": {
        cursor: "crosshair !important",
      },
    },
  });
  
  export default styles;
  