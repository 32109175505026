const styles = (theme) => ({
    centerContainer: {
      display: "grid",
      placeItems: "center",
      // padding: "52px 0 42px 0",
    },
    fullWidth: {
      width: "100%",
      maxWidth: 920,
    },
    imgAlters: {
      filter: "drop-shadow(0px 0px 10px rgba(51, 51, 51, 0.1))",
      borderRadius: 4,
      boxShadow: "0px 0px 10px rgb(51 51 51 / 10%)",
    },
    externalPlayerContainer: {
      width: 566,
      height: 315,
      [theme?.breakpoints?.down?.("md")]: {
        width: 476,
        height: 315,
      },
      [theme?.breakpoints?.down?.("sm")]: {
        width: 720,
        height: 417,
      },
      [theme?.breakpoints?.down?.("xs")]: {
        width: 328,
        height: 191,
      },
    },
    centerSelf: {
      alignSelf: "center",
      justifySelf: "center",
    },
    imageFit: {
      objectFit: "contain",
      maxWidth: "100%",
    },
    outerZoomConatiner: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
    zoomAddonOuterDiv: {},
    zoomAddonImage: {},
    mobileViewFullWidth: {
      width: "100%",
      padding: "0px 0 24px 0",
      minHeight: "250px",
      // placeItems: "center",
    },
    sliderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: 250,
    },
  });
  
  export default styles;
  